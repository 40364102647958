<template>
  <page-content v-if="view_permission" :show-search="true" :show-stat="true" root-element-style="padding-bottom: 30rem">

    <template #stat>
      <table-stat :stat="stat" @showMoreDetail="showMoreDetail"/>
    </template>

    <template #search>
      <table-search
        ref="search"
        v-model="filterValue"
        :fields="search_fields"
        @runSearch="runSearch"
        @changeField="searchChangeField"
      />
    </template>

    <template #default>
      <table-header
        ref="header"
        :title="title + ' '"
        :titleBadge="srTitleBadgeObj"
        :button-actions="header_actions"
        @runSearch="runSearch"
        :hide-search="false"
        :search-tips="$t('device_view.search_tips')"
      />

      <table-view
        :overview="overview"
      />

      <Modal
        :title="$t('device_view.more_detail')"
        :visible="show_more_detail"
        :ok-only="true"
        :no-close-on-backdrop="true"
        :confirm-fun="hideMoreDetail"
        :cancel-fun="hideMoreDetail"
      >
        <ModalMoreDetail
          :stat="stat"
        />
      </Modal>

    </template>

  </page-content>
  <b-card v-else>{{ $t('permission.no_view') }}</b-card>
</template>

<script>
import common from '@/common'
import TableContent from '@/components/TableContent'
import TableHeader from '@/components/TableHeader'
import TableSearch from '@/components/TableSearch'
import PageContent from '@/components/PageContent'
import TableStat from '@/views/bedExitMonitor/deviceView/TableStat'
import TableView from '@/views/bedExitMonitor/deviceView/TableView'
import Modal from '@/components/Modal'
import ModalMoreDetail from '@/views/bedExitMonitor/deviceView/ModalMoreDetail'

// const signalR = require('@microsoft/signalr')
import { srHub, srStart, srStop, srUpdateFilter, srStateMap, srEventMap } from '@/libs/signalr-hub'
import { TagManager } from '@/libs/ez-utils'

export default {
  name: 'bedexitview',
  components: {
    ModalMoreDetail,
    Modal,
    TableView,
    TableStat,
    PageContent,
    TableSearch,
    TableHeader,
    TableContent,
  },
  data() {
    return {
      title: common.getMenuName('bedexitview'),
      // is_admin: common.isAdmin(),
      view_permission: common.checkPermission('Mon_Menu_View'),
      stat: {},
      overview: [],
      show_more_detail: false,
      header_actions: [],

      search_fields: [
        {
          field: 'companyID',
          label: 'common.company',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'facilityID',
          label: 'common.facility',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'floorID',
          label: 'common.floor',
          type: 'select',
          options: []
        },
        {
          field: 'roomID',
          label: 'common.room',
          type: 'select',
          options: []
        },
        {
          field: 'isOnBed',
          label: 'device_view.member_status',
          type: 'select',
          options: [
            {
              text: common.getI18n('device_view.member_status_onBed'),
              value: true
            },
            {
              text: common.getI18n('device_view.member_status_offBed'),
              value: false
            },
          ]
        },
        {
          field: 'isOnline',
          label: 'device_view.device_status',
          type: 'select',
          options: [
            {
              text: common.getI18n('device_view.device_status_on'),
              value: true
            },
            {
              text: common.getI18n('device_view.device_status_off'),
              value: false
            },
          ]
        },
        // {
        //   field: 'orderBy',
        //   label: 'common.sorting_method',
        //   type: 'select',
        //   clearable: false,
        //   options: [
        //     {
        //       text: common.getI18n('common.sort_by').replace('{method}', common.getI18n('common.bed')),
        //       value: 'BedID'
        //     },
        //     {
        //       text: common.getI18n('common.sort_by').replace('{method}', common.getI18n('common.time')),
        //       value: 'MessageUpdateTime'
        //     },
        //   ]
        // }
      ],
      filterValue: {
        companyID: common.getCompanyId(),
        facilityID: common.getFacilityId(),
        floorID: common.getCustomConfigByKey('floorID') ?? null,
        roomID: common.getCustomConfigByKey('roomID') ?? null,
        isOnBed: common.getCustomConfigByKey('BEMonitorIsOnBed') ?? null,
        isOnline: common.getCustomConfigByKey('BEMonitorIsOnline') ?? null,
        // isActive: true,
        // orderBy: 'BedID',
      },

      // SignalR
      srHub,
      srHubTimerId: null,
      srAutoRestart: true,
      srEventTags: new TagManager(),
    }
  },
  computed: {
    srTitleBadgeObj: function () {
      const obj = {
        // text: this.$t('signal_r.real_time_update') + '-',
        text: '',
        variant: 'success'
      }
      // 'Connection.state' could be one of these: 'Connected', 'Connecting', 'Disconnected', 'Disconnecting', 'Reconnecting'
      if (this.srHub.state === 'Connected') {
        // obj.text += this.$t('signal_r.connected')
      } else if (['Connecting', 'Disconnecting', 'Reconnecting'].includes(this.srHub.state)) {
        obj.text += this.$t('signal_r.connecting')
        obj.variant = 'warning'
      } else if (this.srHubTimerId !== null) {
        obj.text += this.$t('signal_r.waiting_to_reconnect')
        obj.variant = 'warning'
      } else {
        obj.text += this.$t('signal_r.disconnected')
        obj.variant = 'danger'
      }
      return obj
    },
    srState: () => srHub.state
  },

  watch: {
    srState: function(_newState, _oldState) {
      this.srSetIsUpdateBEMonitor(true)
    }
  },

  created() {
    // if (!this.view_permission) return

    this.srSetIsUpdateBEMonitor(true)

    srHub.on(this.srEventTags.adder(srEventMap.OverviewRefresh), data => {
      console.log('SignalR "OverviewRefresh": ', data)
      this.getList()
    })

    // OverviewUpdate (Only one bed information will be passed on)
    srHub.on(this.srEventTags.adder(srEventMap.OverviewUpdate), bedObj => {
      console.log('SignalR "OverviewUpdate": ', bedObj)
      this.srOverviewUpdate(bedObj)
    })

    // OverviewRemove (Only one bed information will be passed on)
    srHub.on(this.srEventTags.adder(srEventMap.OverviewRemove), bedID => {
      console.log('SignalR "OverviewRemove": ', bedID)
      this.srOverviewRemove(bedID)
    })

    // OverviewSilent (Only one bed information will be passed on)
    srHub.on(this.srEventTags.adder(srEventMap.OverviewSilent), data => {
      console.log('SignalR "OverviewSilent": ', data)
      this.srOverviewSilent(data)
    })

    // OverviewCount (Facility related)
    srHub.on(this.srEventTags.adder(srEventMap.OverviewCount), data => {
      console.log('SignalR "OverviewCount": ', data)
      this.stat = data
    })
  },

  activated() {
    // console.log('BEMView mounted')
    if (!this.view_permission) return

    if (common.isAdmin()) {
      common.getSearchCompanyOptions('companyID', this)
      common.getSearchFacilityOptions('facilityID', this.filterValue.companyID, this)
    }
    common.getSearchFloorOptions('floorID', this.filterValue.facilityID, this)
    common.getSearchRoomOptions('roomID', this.filterValue.floorID, this)
    this.getList()
  },
  deactivated() {
    this.$refs.search.resetFilterValueToFiltered()
  },

  beforeDestroy() {
    // console.log('BEMView beforeDestroy')
    this.srSetIsUpdateBEMonitor(false)
    this.srEventTags.value.forEach(eventName => {
      srHub.off(eventName)
    })
  },

  // beforeRouteEnter(to, from, next) {
  //   console.log('BEMView beforeRouteEnter')
  //   next(vm => {
  //     console.log('BEMView routeEnter')
  //   })
  // },

  // beforeRouteLeave(to, from, next) {
  //   console.log('BEMView beforeRouteLeave')
  //   next()
  // },

  methods: {
    srSetIsUpdateBEMonitor(isUpdate) {
      if(srHub.state === srStateMap.Connected) {
        srHub.invoke('IsUpdateBEMonitor', isUpdate)
      }
    },
    srOverviewUpdate(bedObj) {
      // do nothing while bedObj is not current facility (which should trigger "OverviewRemove" instead)
      const search_condition = this.$refs.search.search_condition
      if (![bedObj.facilityID, null, undefined].includes(search_condition.facilityID)) return false

      // otherwise
      const roomIDs = this.overview.map(room => room.roomID)
      const roomIndex = roomIDs.indexOf(bedObj.roomID)
      if (roomIndex >= 0) { // match room
        const bedIDs = this.overview[roomIndex].beds.map(bed => bed.bedID)
        const bedIndex = bedIDs.indexOf(bedObj.bedID)
        if (bedIndex >= 0) { // update the matching bed
          bedObj = Object.assign(this.overview[roomIndex].beds[bedIndex], bedObj)
          this.$set(this.overview[roomIndex].beds, bedIndex, bedObj)
        } else { // show new bed in matching room
          bedObj = Object.assign({ isSilent: false }, bedObj)
          this.overview[roomIndex].beds.push(bedObj)
        }
      } else { // show new bed in new room
        this.overview.push({
          beds: [bedObj],
          floorID: bedObj.floorID,
          floorName: bedObj.floorName,
          roomID: bedObj.roomID,
          roomName: bedObj.roomName
        })
      }
      this.overviewFilter()
    },
    overviewFilter() {
      const search_condition = this.$refs.search.search_condition
      const search_content = this.$refs.header.search_content
      for (const [roomIndex, room] of this.overview.entries()) {
        for (const [bedIndex, bed] of room.beds.entries()) {
          if (
            ![bed.isOnBed, null, undefined].includes(search_condition.isOnBed)
            || ![bed.isOnline, null, undefined].includes(search_condition.isOnline)
            || (!bed.memberName.includes(search_content) && !bed.bedNumber.includes(search_content))
          ) {
            this.$delete(this.overview[roomIndex].beds, bedIndex)
          }
        }
        if (
          ![room.floorID, null, undefined].includes(search_condition.floorID)
          || ![room.roomID, null, undefined].includes(search_condition.roomID)
          || room.beds.length === 0
        ) {
          this.$delete(this.overview, roomIndex)
        }
      }
    },
    srOverviewRemove(bedID) {
      for (const [roomIndex, room] of this.overview.entries()) {
        for (const [bedIndex, bed] of this.overview[roomIndex].beds.entries()) {
          if (bedID === bed.bedID) {
            if (this.overview[roomIndex].beds.length > 1) { // delete bed
              this.$delete(this.overview[roomIndex].beds, bedIndex)
            } else { // delete room
              this.$delete(this.overview, roomIndex)
            }
          }
        }
      }
    },
    srOverviewSilent(data) {
      for (const [roomIndex, room] of this.overview.entries()) {
        for (const [bedIndex, bed] of this.overview[roomIndex].beds.entries()) {
          if (data.bedID === bed.bedID) {
            this.$set(this.overview[roomIndex].beds[bedIndex], 'isSilent', data.isSilent)
          }
        }
      }
    },
    // // Notify the srServer that the facility ID has changed so that "OverviewCount" will respond correctly.
    // async srUpdateFilter() {
    //   return await this.srHub.invoke('UpdateFilter', this.$refs.search.search_condition.facilityID)
    // },

    async getList() {
      const api = '/bemonitor/GetMonitorOverview'
      const search = this.getSearchCondition()
      const url = common.getTableUrl(api, search, {}, {})

      return common.apiGetData(url)
        .then(res => {
          this.stat = res.data.overview.count
          this.overview = res.data.overview.rooms
        })
    },

    async runSearch () {
      // const vmOfSearch = this.$refs.search
      // common.syncSearchFields({
      //   companyID: vmOfSearch.list.companyID,
      //   facilityID: vmOfSearch.list.facilityID,
      //   floorID: vmOfSearch.list.floorID,
      //   roomID: vmOfSearch.list.roomID,
      //   BEMonitorIsOnBed: vmOfSearch.list.isOnBed,
      //   BEMonitorIsOnline: vmOfSearch.list.isOnline,
      // })
      if (this.filterValue.facilityID) {
        srUpdateFilter(this.filterValue.facilityID)
      }
      await this.getList()
    },
    async searchChangeField(field, value) {
      await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: false
      })
    },

    getSearchCondition: function () {
      this.$refs.search.buildSearchCondition()
      this.$refs.header.buildSearchContent()
      let where = {}
      where['searchContent'] = this.$refs.header.search_content
      where = Object.assign(where, this.$refs.search.search_condition)
      return where
    },
    showMoreDetail: function () {
      this.show_more_detail = true
    },
    hideMoreDetail: function () {
      this.show_more_detail = false
    },
  }
}
</script>
